import './CommunityButtons.scss'

interface ICommunityButtonsProps {
  code?: string
  paper?: string
  className?: string
}

const CommunityButtons = ({
  code,
  paper,
  className = '',
}: ICommunityButtonsProps) => {
  if (code || paper) {
    return (
      <>
        {paper && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Go to paper"
            href={paper}
            className={`Paper__button ${className}`}
            data-testid="paper-button"
          >
            <i className="bx bx-book mr-1 text-lg"></i>PAPER
          </a>
        )}
        {code && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Go to code"
            href={code}
            className={`Code__button ${className}`}
            data-testid="code-button"
          >
            <i className="bx bx-git-branch mr-1 text-lg"></i>CODE
          </a>
        )}
      </>
    )
  } else return null
}

export default CommunityButtons
