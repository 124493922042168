import Seo from '../../components/seo'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import CommunityCard from '../../components/CommunityCards/CommunityCard'
import qml_community from '../../content/qml/demos_community/demos_community'
import MainTitle from '../../components/MainTitle/MainTitle'
import '../../scss/pages/community.scss'
import MarkdownText from '../../components/MarkdownText/MarkdownText'

const {
  meta_title,
  meta_description,
  meta_image,
  title,
  description,
  demos_cards,
} = qml_community

const CommunityPage = () => {
  return (
    <>
      <Breadcrumbs pageTitle={title} />
      <section className="CommunityPage__container">
        <div className="lg:px-8 mb-8">
          <MainTitle title={title} />
          <MarkdownText
            text={description}
            className="CommunityPage__description"
          />
          <hr />
        </div>

        <div className="CommunityPage__cards">
          {demos_cards.map((item, index) => {
            return (
              <div key={`card-${index}`} className="CommunityPage__card">
                <CommunityCard
                  title={item.title}
                  author={item.author}
                  date={item.date}
                  code={item.code}
                  paper={item.paper}
                  description={item.description}
                />
              </div>
            )
          })}
        </div>
      </section>
    </>
  )
}

export default CommunityPage

export const Head = () => {
  return (
    <Seo title={meta_title} description={meta_description} image={meta_image} />
  )
}
